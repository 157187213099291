import React from "react"
import ReactMarkdown from "react-markdown"
import { graphql } from "gatsby"

import Layout from "~/components/layout"
import SEO from "~/components/seo"
import Image from "~/components/image"

const CompanyPage = ({ data }) => {
  const company = data.strapiCompany

  const seo = {
    title: company.title,
    shareImage: company.image,
  }

  const flexJustify = "center"

  return (
    <Layout>
      <SEO seo={seo} />
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-24 mt-4">
        {company.image && (
          <div className="md:col-span-2 md:pr-4">
            <Image
              className="rounded-md"
              image={company.image}
              alt="Company Image"
            />
          </div>
        )}
        <div className={`flex flex-col justify-${flexJustify}`}>
          <div className="mb-4">
            <h1 className="text-4xl mb-1">{company.title}</h1>
          </div>
          <div className="w-full">
            {company.specifications &&
              company.specifications.map((spec, index) => (
                <div
                  className="w-full flex text-sm justify-between items-between border-b mb-2 pb-1"
                  key={`${spec.key}-${index}`}
                >
                  <span className="font-extralight">{spec.key}</span>
                  <span>{spec.value}</span>
                </div>
              ))}
          </div>
          <a
            href={company.url}
            target="_blank"
            rel="noreferrer"
            className="p-4 text-center font-medium rounded-md border-2 mt-4"
          >
            探索更多
          </a>
        </div>
      </div>
      <div className="my-6 mb-24">
        <h1 className="text-4xl font-bold text-center">公司简介</h1>
        <hr className="mt-6 mb-12 m-auto w-24 border-t-4" />
        <ReactMarkdown
          className="prose md:w-4/5 m-auto"
          children={company.description}
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query CompanyQuery($slug: String!) {
    strapiCompany(slug: { eq: $slug }) {
      id
      title
      description
      url
      slug
      image {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`

export default CompanyPage
